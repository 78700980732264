
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    function routeToP2() {
      window.location.href = 'https://pages.yammii.net/vue-p2';
    }
    async function pushToP2() {
      await router.push('/vue-p2');
    }
    return {
      routeToP2,
      pushToP2,
    };
  },
});
